import * as React from 'react'
import WpApi from './wpapi'

interface MyProps {
    tup:any
    cb:(tv:any)=>void
}
type MyState = {
    input_timeversion:string
    expanded:boolean
    msg:string
}

class InputAddTimeversion extends React.PureComponent<MyProps,MyState> {
    private year_min = 1950
    private year_max = 2300

    constructor(props: MyProps) {
        super(props)
    	this.change_input_timeversion = this.change_input_timeversion.bind(this)
	this.on_timeversion_key_up = this.on_timeversion_key_up.bind(this)

	this.state = {
	    input_timeversion:'',
	    expanded:false,
	    msg:''
	}
    }
    change_input_timeversion(e:any){
	let v = e.target.value
	let ot = this.props.tup.object_type
	let tt = ot.cft_timetype
	let msg = ''
	if(tt === 'year'){
	    // incremental check while typing
	    const re = /^\d{1,4}$/
	    if(! re.test(v)){
		return
	    }
	    msg = this.validate_year_input(v)
	}
	if(tt === 'timeperiod'){
	    // incremental check while typing
	    const re = /^\d{1,4}[-]*\d*$/
	    if(! re.test(v)){
		return
	    }
	    msg = this.validate_timeperiod_input(v)
	}

	this.setState({
	    msg:msg,
	    input_timeversion: v
	})
    }
    validate_year_input(v:string) : string{
	const re = /^\d{4}$/;
	    
	if(!re.test(v)){
	    return 'year format is not ok'
	}
	let n = Number(v)
	if(n < this.year_min){
	    return `Year 1 (${v}) is too far in the past`

	}
	if(n > this.year_max){
	    return `Year 2 (${v}) is too far in the future`
	}
	
	return 'ok'

    }
    validate_timeperiod_input(val:string) :string{
	let year_min = 1950
	let year_max = 2300
	const re2 = /^(\d{4})-(\d{4})$/
	if(!re2.test(val)){
	    return 'input is not correct (yet)'
	}
	let r = re2.exec(val)
	if(r == null){return 'input is not correct'}
	let r1 = Number(r[1])
	let r2 = Number(r[2])
	if(r1 > r2){
	    return 'Year 1 should be earlier'

	}
	if(r1 < year_min || r1 > year_max){
	    return `Year 1 (${r1}) is too far away`

	}
	if(r2 < year_min || r2 > year_max){
	    return `Year 2 (${r2}) is too far away`

	}

	return 'ok'
    }
    /*
     *  submit a new timeversion (year or timeperiod)
     */
    on_timeversion_key_up(e:any){
	let v = e.target.value
	let tup = this.props.tup
	let ot = tup.object_type
	let tt = ot.cft_timetype
	let d = {
	    cmd: 'create_time_version',
	    post_id: tup.id,
	    timetype: tt,
	    period: v
	}

	if(e.keyCode === 13){
	    console.log('submit timeversion')
	    console.log(`  timeversion ${v} for ot.tt ${tt}`)
	    if(tt === 'timeperiod'){

		let msg = this.validate_timeperiod_input(v)
		if( msg !== 'ok'){
		    console.log(` cannot post ${msg}`)
		    return
		}
	    }
	    else if(tt === 'year'){
		let msg = this.validate_year_input(v)
		if(msg !== 'ok'){
		    console.log(` cannot post ${msg}`)
		    return
		}
	    }

	    WpApi.do_post('/objects',d, (r) =>{
		console.log(r)
		if(r.instance !== undefined){
		    this.setState({expanded:false})
		    this.props.cb(r.instance)
		}
		if(r.error !== undefined){
		    this.setState({msg:r.error})
		}
	    })
	}
    }
    
    render(){
	let tup = this.props.tup
	
	let placeholder = ""
	let ot = tup.object_type
	let tt = ot.cft_timetype
	if(tt === "year"){
	    placeholder = "- type a year version to add"
	}
	else if(tt === "timeperiod"){
	    placeholder = "- type a timeperiod version to add (YYYY-YYYY) "
	}
	if(this.state.expanded){
	    return (
		<div className="input-wrapper add-timeversion">
	    	    <input size={100}  placeholder={placeholder}
			   value={this.state.input_timeversion}
	    	           onKeyUp={this.on_timeversion_key_up}
			   onChange={this.change_input_timeversion}/>
		    <div className="msg" > {this.state.msg}</div>
		</div>
	    )
	}
	return (
	    <div className="add-new-timeversion cft-small-icon btn" onClick={()=>{this.setState({expanded:true})}} >
		<img alt="" src={require("./img/plus-icon.png")} />
	    </div>
	)

    }
}

export default InputAddTimeversion
