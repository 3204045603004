import * as React from 'react'
import WpApi from './wpapi'

interface MyProps {
    tup:any
    post_id:number
    cb_parent:   (e: any, cb: (r: any) => void) => void
    
}

type MyState = {
    mode:string
    inputvalue:string
    indicator:string
    uom:string
}
class FeatureInputRow extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)

	this.click_value = this.click_value.bind(this)
	this.click_show_votes = this.click_show_votes.bind(this)
	this.click_show_history = this.click_show_history.bind(this)
	this.change_input_val = this.change_input_val.bind(this)
	this.input_submit = this.input_submit.bind(this)
	this.change_select = this.change_select.bind(this)
	this.on_key_up = this.on_key_up.bind(this)
	
	this.state = {
	    mode:'read',
	    indicator:'',
	    uom:'',
	    inputvalue:''
	}
    }
    componentDidMount(){
	let tup = this.props.tup
	if(tup.winner){
	    this.setState({inputvalue:tup.winner})
	}
    }    
    change_select(e:any){
	let v = e.target.value
	console.log(v)
	this.setState({
	    inputvalue : v,
	    mode:'read'
	}, () => {
	    this.post_vote('choice')	 
	})
    }
    change_input_val(e:any){
	let v = e.target.value
	if(this.props.tup.cft_blocktype.includes('num-input')){
	    if(isNaN(Number(v))){
		return
	    }
	}
	console.log(v)
	this.setState({inputvalue : v})
    }
    // submit changed input (e.g. a number)
    input_submit(e:any){
	console.log('submit')
	let tup = this.props.tup
	if(tup.cft_blocktype.includes('num-input')){
	    this.post_vote('add_number')
	}else if(tup.cft_blocktype.includes('text-input')){
	    this.post_vote('add_text')
	}
	this.setState({mode:'read'})
    }
    post_vote(vt:string){
	let tup = this.props.tup
	let d : {[k:string]:string} = {
	    block_type: tup.block_type,
	    cft_block: tup.key,
	    aspect:"current",
	    indicator: this.state.indicator,
	    uom: this.state.uom,
	    post_id: this.props.post_id.toFixed(),
	    value: this.state.inputvalue,
	    attr_type: vt
	}
	WpApi.do_post('/block-attr',d, (r) => {
	    console.log(r)
	})
    }
    str_val(v:string){
	let tup = this.props.tup
	let cfid = tup.cft_blocktype
	
	if(cfid.includes('option-input')){
	    return tup.choices[v]
	}
	return v
    }
    on_key_up(e:any){
	if(e.keyCode === 13){
	    // Enter key was pressed
	    this.input_submit(null)
	}
    }
    render_indicator_selector(ilist:Array<any>){

	if(ilist === undefined || ilist.length === 0){return}
	return (
	    <div>
		<select onChange={(e:any) => {this.setState({indicator:e.target.value})}}>
	         <option value="">- kies indicator -</option>
		{ilist.map((tup:any) => ( <option key={tup.id} value={tup.id}> { tup.title } </option>))}
		</select>
	    </div>
	)
    }
    render_uom_selector(ilist:Array<any>){
	if(ilist === undefined || ilist.length === 0){return}
	return (
	    <div>
		<select onChange={(e:any) => {this.setState({uom:e.target.value})}}>
	         <option value="">- kies UOM -</option>
		{ilist.map((tup:any) => ( <option key={tup.id} value={tup.id}> { tup.title } </option>))}
		</select>
	    </div>
	)
    }
    render_edit_widget(){
	let tup = this.props.tup
	let append = tup.append
	if(tup.cft_blocktype.includes('num-input')){
	    return (
		<div className="input-with-btn cft-small-icon">
		    <input placeholder="-number-" value={this.state.inputvalue} onKeyUp={this.on_key_up}
		onChange={this.change_input_val} />
		    <span> {append} </span>
		    {this.render_indicator_selector(tup.indicators)}
		{this.render_uom_selector(tup.uoms)}
		    <img 
			src={require("./img/save-icon-floppy.jpg")}
			alt=""
			onClick={this.input_submit} />
		    </div>
	    )

	}else if(tup.cft_blocktype.includes('option-input')){
	    let choices = { 'kies': '- kies -', ...tup.choices}
	    let options = Object.entries(choices).map(([k,lab]:[string,any]) => (
		<option key={k} value={k}>{lab}</option>
	    ))
	    return (
		<select value={this.state.inputvalue}
		onChange={this.change_select}
		    >
		    {options}
		</select>
	    )
	}else if(tup.cft_blocktype.includes('text-input')){
	    return (
		<div className="input-with-btn cft-small-icon">
		    <input value={this.state.inputvalue} onKeyUp={this.on_key_up}
		onChange={this.change_input_val} />
		    <span> {append} </span>
		    <img src={require("./img/save-icon-floppy.jpg")}
			alt="" onClick={this.input_submit} />
		</div>
	    )
	    
	}else{
	    return (<div> ? </div>)
	}
    }
    click_show_votes(){
	console.log('show votes')
	let cmd = 'text-vote'
	let tup = this.props.tup
	let bt = tup.cft_blocktype
	if(bt.includes('option-input')){
	    cmd = 'choice-vote'
	}else if(bt.includes('num-input')){
	    cmd = 'number-vote'
	}
	this.props.cb_parent({
	    cmd: cmd,
	    label: 'Claims + add claim',
	    post_id: this.props.post_id,
	    aspect:'current',
	    acf_key: this.props.tup.key,
	    with_history: true,
	    history_popup:{
		cmd: 'show-history',
		label: 'history',
		post_id: this.props.post_id,
		aspect:'current',
		acf_key: this.props.tup.key
	    }
	}, (e:any)=>{
	    console.log('return from cb-parent')
	})
	    
    }
    click_show_history(){
	console.log('show history')
	this.props.cb_parent({
	    cmd: 'show-history',
	    label: 'history',
	    post_id: this.props.post_id,
	    aspect:'current',
	    acf_key: this.props.tup.key
	}, (e:any)=>{
	    console.log('return from cb-parent')
	})
	    
    }
    click_value(){
	if(this.state.mode === 'read'){
	    this.setState({mode:'write'})
	}
	
    }
    get_user_vote(){
	let tup = this.props.tup	
	let append = tup.append
	//if(tup.uservote === undefined){
	  //  return null
    //}
	//let val = tup.uservote.value
	let val = ''
	if(this.state.inputvalue !== ""){
	    val = this.state.inputvalue 
	}

	//if(this.props.config.dashboard_mode === "adviseur"){
	//  val = tup.winner
	//}
	let strval = this.str_val(val)
	return this.state.mode === "read" ? (
	    <div className="value editable cft-small-icon" onClick={this.click_value}>
		<span> {strval} </span> {append} 
		<img
		    alt="" src={require("./img/edit-pencil-4.jpg")} />
		</div>
	) : (
	    <div className="edit-widget">
		{this.render_edit_widget()}
	    </div>
	)
	

    }
    click_show_permissions(){
	console.log('todo')
    }
    render_permissions_button(){
	return (
	    <div className="show-votes cft-small-icon" onClick={this.click_show_permissions}>
		<img  alt=""
		    src={require("./img/icon-permissions.png")}/>
		</div>
	)
	    
    }
    render(){
	let default_val = ""
	let uservote = this.get_user_vote()
	let tup = this.props.tup
	// 	        <div className="show-history" onClick={this.click_show_history}>
	return (
    	    <div key={tup.name} className="input-field feature">
		<div className="label-and-default">{tup.label} {default_val}</div>
		<div className="value-container">
		  {uservote}
	            <div className="buttons">
			<div className="show-votes cft-small-icon" onClick={this.click_show_votes}>
			    <img 
				alt=""
				src={require("./img/icon-history.png")}/>
			</div>
			{this.render_permissions_button()}
		    </div>
		</div>
	    </div>
	)
    }

}

export default FeatureInputRow
