import * as React from 'react'
import WpApi from './wpapi'
import ObjectView from './ObjectView'
import HrefLink from './HrefLink'

import MyConf from './config'

interface MyProps {
    group_id:string
}
type MyState = {
    organisation:any
    new_timeversions:Array<any>
    relationtypes:any
    path_to_org:Array<any>
    object:any
}

class OrgDash extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>

    constructor(props: MyProps) {
        super(props)
	this.state = {
	    new_timeversions: [],
	    path_to_org: [],
	    organisation:null,
	    object:null,
	    relationtypes:{}
	}
    }

    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	let path = '/group-content'
	let d : {[k:string]:string}= {
	    mode: 'get-content',
	    group_id: this.props.group_id
	}
	let p = new URLSearchParams(window.location.search)
	let object_id = p.get('instance')
	if(object_id !== null){
	    d.object_id = object_id
	    d.mode = "get-object"
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    console.log('got r')
	    if(r.org !== undefined){
		this.setState({
		    organisation: r.org,
		    relationtypes:r.relationtypes,
		})
	    }else if(r.object !== undefined){
		this.setState({
		    object: r.object,
		    path_to_org: r.path_to_org
		})
	    }
	});

    }
    open_popup(e:any, cb:(r:any)=>void){
        console.log(`open-popup cmd: ${e.cmd}`)
    }
    
    render_org(){
	let org = this.state.organisation
	if(org === null){ return }
	let cfg = {
	    params: MyConf.params,
	    view : "org-dash"
	    
	}
	if(org.title === undefined){
	    return (<div>--</div>)
	}
	return (
	    <div className="org-item">
		<h4>{org.title}</h4>
		<ObjectView instance_id={org.id} config={cfg}/>
	    </div>
	)
    }
    render_obj(){
	let obj = this.state.object
	if(obj === null){ return }
	let cfg = {
	    params: MyConf.params,
	    view : "org-dash"
	    
	}
	return (
	    <div className="org-item">
		<h4>{obj.title}</h4>
		<ObjectView instance_id={obj.id} config={cfg}/>
	    </div>
	)

    }
    render_path_elm(x:any){
	if(x.winner === undefined){return null}
	let params = "instance="+x.winner.id
	if(x.winner.bbgrouplink !== undefined){
	    params = x.winner.bbgrouplink + '?' + params
	}
	let label = "> "+x.winner.title
	return (
	    <div key={x.winner.id} className="item"> 
		 <HrefLink text={label} page="org-dash" params={params} />
	    </div>
	)
    }
    render_path_to_org(){
	let path = this.state.path_to_org.reverse()
	if(path.length===0){return}
	return (
	    <div className="bread-crums">
		{path.map((x:any) => this.render_path_elm(x))} 
	    </div>
	)
	// 
		    // 
    }
    render(){
	return (
	    <div className="org-dash">
	    {this.render_path_to_org()}
		{this.render_org()}
		{this.render_obj()}
	    </div>
	)
    }
}

export default OrgDash
