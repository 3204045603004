import * as React from 'react'
import WpApi from './wpapi'


interface MyProps {
}
type MyState = {
    warning:string,
    org:any,
    orglist: Array<any>
}


class OrgChecker  extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    constructor(props: MyProps) {
        super(props)

	this.pop_state = this.pop_state.bind(this)
	this.state = {
	    warning:'',
	    org:null,
	    orglist:[]
	}
    }
    // user clicked 'Back'
    pop_state(e:any){
	let p = new URLSearchParams(window.location.search)
	if(p.get('org') === null){
	    this.setState({org:null})
	}
    	e.preventDefault()

    }
    componentDidMount(){
	window.addEventListener("popstate", this.pop_state)
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	let path = '/group-adm'
	let d = {
	    'mode': 'get-groups'
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    console.log('got r')
	    if(r.list !== undefined){
		this.setState({orglist:r.list})
	    }
	})
    }
    rest_submit(cmd:string,name:string){
	let path = '/group-adm'
	let org = this.state.org
    	let d : {[k:string]:string}= {
	    cmd:cmd,
	}
	if(cmd === 'create-org-object'){
	    d.group_id = org.bb_group_id
	    d.name = name
	}else if(cmd === 'create-bbgroup'){
	    d.org_id = org.id
	    d.name = name
	}
	WpApi.do_post(path,d,(r)=>{
	    console.log(r)
	    if(r.status === 'ok'){
		window.location.reload()

	    }
	    if(r.error !== undefined){
		this.setState({warning:r.error})
	    }
	})
    }
    on_chosen_org(tup:any){
	const url = new URL(window.location.href)
	url.searchParams.set("org",tup.rkey)
	window.history.pushState({org:tup.rkey},"",url)
	this.setState({
	    org:tup
	})
    }
    render_org_item(tup:any){
	let has_vendors = 'No'
	let cl = ''
	if(tup.vendors !== undefined ){
	    if(tup.vendors.length > 0 ){
		has_vendors = 'Yes'
	    }else if(tup.vendors.length > 1){
		has_vendors = 'ERROR'
		cl = 'error'
	    }
	}
	let gstatus = tup.bbgroup === undefined ? "" : tup.bbgroup.status

	return (
	    <div key={tup.rkey} className="row" onClick={()=>{ this.on_chosen_org(tup)}}>
	      <div> {tup.title} </div>
	      <div> {tup.bbgroup_name} </div>
	      <div > {gstatus} </div>
	      <div className={cl}> {has_vendors} </div>
	      <div className="org" > {tup.object_status} </div>
	    </div>
	)
    }
    render_org_list(){
	if(this.state.orglist.length === 0){return}
	return (
	    <div className="group-list">
	    <div className="row header">
	       <div> GM-database object</div>
	       <div> BB-group</div>
	       <div> BB-group-status </div>
	       <div> has MK-vendor </div>
	       <div> Object-status </div>
	       </div>
	    {this.state.orglist.map((tup:any) => {
		return this.render_org_item(tup)
	    })}
	    </div>
	)
    }
    render_bbgroup_aspect(){
	let tup = this.state.org
	if(tup.bbgrouplink === undefined){
	    return (
		<div className="aspect-box">
		<div className="warn"> This org has no linked bb-group yet! </div>
		    <button onClick={()=>{
			this.rest_submit('create-bbgroup',tup.title)
		    }}>Create a linked BB-group </button>
		    </div>
	    )
	}else{
    	    return  (
		<div className="aspect-box">BBgroup:
    		<div>  name + link: <a href={tup.bbgrouplink}>{tup.bbgroup_name}</a></div>
		<div>  status: {tup.bbgroup.status}</div>
		</div>
    	    )
	}
    }
    render_vendor(u:any){
	let url = '/wp-admin/user-edit.php?user_id='+u.id+'#marketking_user_vendor_profile'
	let surl = '/stores-list/' + u.mk_store_url
	return (
	    <tr key={u.id}> 
		<td>{u.user_nicename}
		    <a href={url}> [ open ] </a> </td>
	        <td> {u.user_email} </td>
	        <td> <a href={surl}>{u.mk_store_name}</a></td>
	    </tr>
	)
    }
    render_vendors(){
	let tup = this.state.org
	if(tup.vendors.length === 0){return null}

	return (
	    <div className="aspect-box">
	    <div><br/>  MK-vendors: </div>
	    <table className="mk-vendors">
	    <tr>
	       <th>User</th>
	       <th>Email</th>
	       <th>Store</th>
	       </tr>
	    {tup.vendors.map((x:any) => this.render_vendor(x))}
	    </table>
	    </div>
	)
    }
    render_org(){
	let tup = this.state.org
	if(tup.db_url === undefined){
	    // orphaned bb-group
	    return (
		<div className="aspect-box">
		    <div>    BB-group {tup.bbgroup_name} </div>
		    <a href={tup.bbgrouplink}>BB-Group</a>

    		    <div className="warn"> 
		      this group is orphaned, a linked db-object is missing
		     </div>
		    <button onClick={()=>{
			this.rest_submit('create-org-object',tup.bbgroup_name)
		    }}>Create a linked DB-object </button>
		</div>
	    )
	}else{
	    return (
		<div>
	    	    <div className="aspect-box">
	    		<div> Organisation: (<a href={tup.db_url}> {tup.title} </a>) </div>
	    		<div> Object-status: {tup.object_status} </div>
		    </div>
    		    {this.render_bbgroup_aspect()}
		    {this.render_vendors()}
		</div>
	    )
	}
    }
    render(){
	let tup = this.state.org
	return (
	    <div className="org-group-state-adm">
	    {tup=== null ? this.render_org_list():this.render_org()}
	    {this.state.warning === '' ? null : <div className="warning">{this.state.warning}</div>}
	    </div>
	)
    }

}

export default OrgChecker


