import MyConf from './config';

/*
 * Note on using cft-fast-mode 
 *  this is a rest-route provided by WP must-use plugin cf_exclude_plugins
 */



class WpApi{
    public static nonce: string = ''
    
    public static do_fetch(method:string,path:string, data:any,cb: (r:any) => void){
	console.log(`do http ${method}`)

	const conf = (new MyConf()).get()
	var headers : { [string: string]: string } 	= {
	    'X-CFT-FAST-MODE': 'true'
	}

	if(method === 'GET'){
	    return 'error no get allowed for do_fetch'
	}
	headers['Content-Type'] = 'application/json'

	var fullpath = conf.wp_api_path + path
	var url = fullpath
	if(conf.params.nonce){
	    // production-mode (embedded in WP)
	    headers['X-WP-Nonce'] = conf.params.nonce
	}else{	
	    // dev-mode ; running of nodejs-dev-server, CORS-mode
	    if(conf.params.deploymode === 'develop'){
		url = conf.wp_api_host + fullpath
	    }
	}


	let bodydata = JSON.stringify(data)
	var requestOptions = {
	    method: method,
	    headers: headers,
	    body: bodydata
	}
	console.log(url)
	fetch(url,requestOptions)
	    .then(response => response.json())
	    .then(data => {
		cb(data)
	    })
    }
    public static do_post(path:string, data:any,cb: (r:any) => void){
	this.do_fetch('POST',path,data,cb);
    }
    public static do_patch(path:string, data:any,cb: (r:any) => void){
	this.do_fetch('PATCH',path,data,cb);
    }
    public static do_get(path:string, data:{[name:string]:string}, cb: (r:any) => void){
	const conf = (new MyConf()).get()
	var headers : { [string: string]: string } 	= {
	    'X-CFT-FAST-MODE': 'true'
	}
	let d = Object.entries(data).map(([key,val])=>{
	    return key + '=' + val
	})
	console.log(d)
	console.log(conf)
	var fullpath = conf.wp_api_path + path

	var url = fullpath
	if(conf.params.nonce){
	    // production-mode (embedded in WP)
	    headers['X-WP-Nonce'] = conf.params.nonce
	}else{	
	    // dev-mode ; running of nodejs-dev-server, CORS-mode
	    if(conf.params.deploymode === 'develop'){
		url = conf.wp_api_host + fullpath
	    }
	}

	url += '?' + d.join('&')
	console.log(url)
	return fetch(url,{ headers })
		.then(response => response.json())
		.then(data => {
		    cb(data)
		})
    }
}

export default WpApi
