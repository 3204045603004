import * as React from 'react';

import ReactModal from 'react-modal';
import AddRelationForm from './AddRelationForm';


interface MyProps {
    cb_parent:(e?: any) => void
}


type MyState = {
    showModal:boolean
    label: string
    history_hdr_label:string
    with_history:boolean
    instance:any
    warning:string
    relation:any
};

class AddRelationModal extends React.PureComponent<MyProps,MyState> {


    public after_vote(e:any){
	console.log('after vote')
	// send signal to parent: the HdaList
	this.props.cb_parent(e)
	this.setState({showModal:false})
    }
    constructor(props: MyProps) {
        super(props)
    	this.handleCloseModal = this.handleCloseModal.bind(this)
	this.after_vote = this.after_vote.bind(this)
	this.state = {
	    showModal:false,
	    with_history:false,
	    history_hdr_label:'undef',
	    instance:null,
	    relation:null,
	    warning:'',
	    label:'undef'
	}
    }
    public handleCloseModal(){
	this.setState({ showModal: false });
    }
    public init(data:any){
	let pop_label = data.label === undefined ? '' : data.label

	console.log(data)
	this.setState({
	    label:pop_label,
	    showModal:true,
	    with_history: data.with_history,
	    instance:data.instance,
	    relation:data.relation

	})
	
	/*
	setTimeout(()=>{
	    this.voteGrid.current!.init(data)
	    },300);
	    */
    }

    render_form(){
	if(this.state.relation === null){
	    return null
	}
	return (
	    <AddRelationForm relation={this.state.relation}
	    cb_parent={this.props.cb_parent}
	    instance={this.state.instance} />
	)
    }
    render_warning(){
	if(this.state.warning === ""){return null}
	return (
	    <div className="warning">
		Warning: {this.state.warning}
	    </div>
	)
    }

    set_warning(txt:string){
	this.setState({warning:txt})
    }
    public render(){
	const customStyles = {
	    content: {
		top: '10%',
		left: '10%',
		right:'20%',
		bottom:'20%'
		
	    },
	};
	return (
	    <ReactModal
	            ariaHideApp={false}
	            contentLabel="my test modal"
	            style={customStyles}
	            isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
		>
		<div className="popup-header">
		    <div className="label">{this.state.label}</div>
		    <div className="popup-buttons">
			
			<span className="cf-icon-btn" data-balloon="Sluit" data-balloon-pos="down" onClick={this.handleCloseModal}><i className="bb-icon-l bb-icon-times"/></span>
		    </div>
		</div>
		<div className="cft-popup-body">
		    {this.render_form()}
		    {this.render_warning()}
		</div>
	     </ReactModal>
	)
    }    
}

export default AddRelationModal;
