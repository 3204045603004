import * as React from 'react'
import InputAddTimeversion from './InputAddTimeversion'
interface MyProps {
    tup:any
    cb:(e:any)=>void
}
type MyState = {
    new_timeversions:Array<any>
}
class TimelineRow extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)

	this.state = {
	    new_timeversions: []
	}
    }
    get_year_or_period(tup:any){
	if(tup.metatype === "year-version"){
	    return tup.year
	}else if(tup.metatype === "timeperiod-version"){
	    return tup.period
	}
    }
    render_instance(tup:any){
	let ot = tup.object_type
	return (
	    <div className="instance" key={tup.id}
		 onClick={(e:any)=>this.props.cb(tup)}>
		<div className="instance-body">
		    <div className="title">{ot.label} : {tup.title}</div>
		    <div className="date">{this.get_year_or_period(tup)}</div>
		</div>

	    </div>
	)
    }
    render_new_timeversions(tup_id:number){
	let list = this.state.new_timeversions

	return (
	    <div className="new-timeversions">
		{list.map((tv:any) => this.render_instance(tv))}
	    </div>
	)
    }
    render_timeline(tup:any){
	let ot = tup.object_type
	return (
	    <div className="timeline collapsed" key={tup.id}>
		<div className="header" onClick={(e:any)=>{
			 let elm = e.target.closest('.timeline')
			 console.log(elm.classList)
			 if(elm.classList.contains('collapsed')){
			     elm.classList.remove('collapsed')
			 }else{
			     elm.classList.add('collapsed')
			 }
		     }}>
		    <div className="title">{ot.label} : {tup.title} [timeline]</div>
		</div>
		<div className="body">
		{Object.entries(tup.timeversions).map(([k,tv]:[string,any]) =>
		    this.render_instance(tv))
		}
	    {this.render_new_timeversions(tup.id)}
	        <InputAddTimeversion
		    tup={tup}
		    cb={(tv:any) => {
			let prev = this.state.new_timeversions
		
			this.setState({new_timeversions: [...prev, tv]})
		    }}
		/>
		    </div>
	    </div>
	)
    }

    render(){
	let tup = this.props.tup
	return (
	    <div>{this.render_timeline(tup)}</div>
	)
    }
}
export default TimelineRow


