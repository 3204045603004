import * as React from 'react'
import WpApi from './wpapi'


import {Instances_mode} from './InstancesListMode'
import TimelineRow from './TimelineRow'
/*
 *  mode:
 *     timeversion ; purpose (for props.cb) is for timeline-types to return a timeversion
 *     navigate    ; purpose is  navigation, to go to ObjectView. We
 *                   dont show timeversions, these can be found via ObjectView of timeline
 */
interface MyProps {
    object_type:any
    cb:(e:any)=>void
    showheader:boolean
    mode: Instances_mode
}
type MyState = {
    new_item_name:string
    instances:Array<any>
    descen:Array<any>
    object_type:any
    msg:string
    filter_txt:string
}

class InstancesList extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	
    constructor(props: MyProps) {
        super(props)
	
	this.on_key_up = this.on_key_up.bind(this)

	this.state = {
	    new_item_name:'',
	    instances:[],
	    descen:[],
	    filter_txt:'',
	    msg:'',
	    object_type:null
	    

	}
    }
    /*
    componentDidUpdate(prevProps:any) {
	if (prevProps.object_type !== this.props.object_type) {
	    console.log('updated obj.type prop')
	}
    }
*/
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	this.setState({object_type:this.props.object_type})
	let ot = this.props.object_type
	if(ot.name === undefined){
	    return
	}
	let path = '/objects'
	let d = {
	    mode: 'get-instances',
	    obtype: ot.name
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
            console.log(r)
            this.setState({
		instances: r.list,
		descen: r.descen
	    })
	    
	})	
    }
    
    
    on_key_up(e:any){
	if(e.keyCode === 13){
	    // Enter key was pressed
		console.log('submit')
		if(this.state.object_type.is_abstract){
		    let m = 'cannot create in instance of an abstract object-type'
		    console.log(m)
		    this.setState({msg:m})
		    return
		}
	    let path = '/objects'
	    let d = {
		cmd: 'create_instance',
		obtype:this.state.object_type.name,
		name: this.state.new_item_name
	    }
	    console.log(d)
	    WpApi.do_post(path, d,(r) => {
		console.log(r)
		if(this.props.mode === "navigation"){
		    window.location.reload()
		}else{
		    if(this.props.mode === "timeversion" && r.instance.metatype === "timeline"){

			// append to list
			let prev = this.state.instances
			this.setState({
			    msg:'Please select a timeversion',
			    instances: [...prev, r.instance]
			})
		    }else{
			this.props.cb(r.instance)
		    }
		}
	    })
	}
    }
    


    render_create_instance_forms(){
	return (
	    <div className="forms">
		{this.state.descen.map((ot:any) => this.render_create_instance_form(ot))}
	    </div>
	)
    }
    render_create_instance_form(ot:any){
	if(ot.name !== this.state.object_type.name){
	    return null
	}
	let label = ot.label
	return (
	    <div className="create-form" key={ot.name}>
		<div> <span> Create a new {label}: </span>
		<input placeholder="- type name -" value={this.state.new_item_name}
	            onKeyUp={this.on_key_up}
		       onChange={(e:any)=>{ this.setState({new_item_name:e.target.value})}}
		    />
		</div>

	    </div>
	)
    }
    render_instance(tup:any){
	let ot = tup.object_type
	if(ot.name !== this.state.object_type.name){
	    return null
	}
	if(!tup.title.includes(this.state.filter_txt)){
	    return null
	}
	if(this.props.mode === "timeversion" && tup.metatype === "timeline"){
	    return (
		<TimelineRow key={tup.id} tup={tup} cb={this.props.cb}/>
	    )
	}
	return (
	    <div className="instance" key={tup.id}
		 onClick={(e:any)=>this.props.cb(tup)}>
		<div className="instance-body">
		    <div className="title">{ot.label} : {tup.title}</div>
		    <div className="date">{tup.date}</div>
		</div>
		<div className="cft-small-icon">
		<img src={require("./img/open-link-icon-11.png")} alt="" />
		</div>
	    </div>
	)
    }
    render_list(){
	let list = this.state.instances
	if(list.length === 0){
	    return null
	}
	return (
	    <div className="instances">
		<div className="body">
		{list.map((tup:any,i:number) => this.render_instance(tup))}
		</div>
	    </div>
	)
    }
    on_select_ot(tup:any){
	this.setState({object_type:tup})
    }
    render_ot_selector(){
	let list = this.state.descen.filter((x:any) => {return ! x.is_abstract})
	return (
	    <div className="ot-selector">
		{list.map((tup)=>{
		    return (<div key={tup.name}>
			<input
			    checked={this.state.object_type.name === tup.name}
			    type="radio"
			    name="obtype"
			    id={tup.name}
			    onChange={()=>{ this.on_select_ot(tup)}}/>
			<label htmlFor={tup.name}> {tup.label} </label>
			</div>
			)
		})}
		</div>
	)
    }
    render_filters(){
	return (
	    <div className="filters">
		<input placeholder="- type to filter -" onChange={(e:any)=>{
		    this.setState({ filter_txt : e.target.value })
		}}/>
		</div>
	)
    }
    render(){
	let ot = this.props.object_type
	return (
	    <div className="instances-list">
		<div className="msg">{this.state.msg} </div>
		{this.props.showheader ? (<h3>Object-type: {ot.label}</h3>):null}
	    {this.render_ot_selector()}
	    {this.render_create_instance_forms()}
	    <div><b> or select below </b>
	    {this.render_filters()}
	    {this.render_list()}
	    </div>
	    </div>
	)
    }
}

export default InstancesList

