import * as React from 'react';

import ReactModal from 'react-modal';
import HistoryGrid from './HistoryGrid';


interface MyProps {

}


type MyState = {
    showModal:boolean,
    label:string
};

class HistoryModal extends React.PureComponent<MyProps,MyState> {
    private grid =  React.createRef<HistoryGrid>();


    constructor(props: MyProps) {
        super(props)
    	this.handleCloseModal = this.handleCloseModal.bind(this)

	this.state = {
	    showModal:false,
	    label:''
	}
    }
    public handleCloseModal(){
	this.setState({ showModal: false });
    }
    public init(e:any){
	let pop_label = e.label === undefined ? '' : e.label
	this.setState({
	    showModal:true,
	    label:pop_label
	})
	setTimeout(()=>{
	    this.grid.current!.init(e)
	},300);
    }

    
    public render(){
	const customStyles = {
	    content: {
		top: '10%',
		left: '10%',
		right:'20%',
		bottom:'20%'
		
	    },
	};
	return (
	    <ReactModal
	            ariaHideApp={false}
	            contentLabel="my test modal"
	            style={customStyles}
	            isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
		>
		<div className="popup-header">
		<div className="label">{this.state.label}</div>

		<div className="popup-buttons">
                  <span className="cf-icon-btn" data-balloon="Sluit" data-balloon-pos="down" onClick={this.handleCloseModal}><i className="bb-icon-l bb-icon-times"/></span>
		</div>
		</div>
		<HistoryGrid ref={this.grid} />
	     </ReactModal>
	)
    }    
}

export default HistoryModal;
